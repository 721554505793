import * as React from "react";
import { 
    IResourceComponentsProps, 
    BaseRecord, 
    HttpError,
    useDeleteMany,
    Authenticated
} from "@refinedev/core";
import {
    useTable,
    List,
    // EditButton,
    ShowButton,
    // DeleteButton,
    TagField,
    useImport, 
    ImportButton,
    // ExportButton,
    // CreateButton
} from "@refinedev/antd";
import { Table, Space, Button } from "antd";
import { useFindCodes } from "../../components/findCode";

import { IFormacion, IEjeTematico, IWPFormacion, ImportFormacion } from "../../interfaces";

export const FormacionList: React.FC<IResourceComponentsProps> = () => {

    const dataId = "id";
    const dataCode = "acf.ca_ejetematico_code";
    const data = {
      dataId,
      dataCode,
    };
    
    const findEjeTematicoCode = useFindCodes({ resource: "ejetematico", data: data });
        
    const importProps = useImport<ImportFormacion>({

        paparseOptions: {
            skipEmptyLines: 'greedy',
            // header: true,
        },
        onFinish: (result) => {
            // success requests response
            // result.succeeded.forEach((item) => {
            //     console.log(item);
            // });
    
            // failed requests response
            result.errored.forEach((item) => {
                console.log(item);
            });
        },
        mapData: (item) => {
            const $codformacion = item.cod_formacion?.trim();
            const $title = item.nombre_formacion?.trim();
            const $content = item.objetivos?.trim();
            const $duracion = Number(item.duracion);
            const $codEjeTematico = item.cod_ejetematico?.trim();
            const $ejetematicoId = findEjeTematicoCode($codEjeTematico);

            return {
                title: $title,
                content: $content,
                acf: {
                    ca_codformacion: $codformacion,
                    ca_duracion: $duracion,
                    ca_ejetematico_id: $ejetematicoId,
                    ca_ejetematico_code: $codEjeTematico,
                },
                status: 'publish'
            };
        },
    });

    const { tableProps } = useTable<IFormacion, HttpError>();

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>(
        [],
    );

    const { mutate, isLoading: deleteManyIsLoading } = useDeleteMany<IFormacion>();

    const deleteSelectedItems = () => {
        mutate(
            {
                resource: "formacion",
                ids: selectedRowKeys.map(String),
            },
            {
                onSuccess: () => {
                    setSelectedRowKeys([]);
                },
            },
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ],
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
        <Authenticated>
        <List
            headerProps={{
                subTitle: (
                    <>
                        <Button
                            type="primary"
                            onClick={deleteSelectedItems}
                            disabled={!hasSelected}
                            loading={deleteManyIsLoading}
                        >
                            Borrar
                        </Button>
                        <span style={{ marginLeft: 8 }}>
                            {hasSelected
                                ? `Se han seleccionado ${selectedRowKeys.length} items`
                                : ""}
                        </span>
                    </>
                ),
                extra: (
                    <Space>
                        <ImportButton {...importProps} >Importar</ImportButton>
                        {/* <ExportButton
                            onClick={triggerExport}
                            loading={exportLoading}
                        >Exportar</ExportButton> */}
                        {/* <CreateButton>Agregar</CreateButton> */}
                    </Space>
                ),
            }}
        >
            <Table {...tableProps} 
                rowSelection={rowSelection}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    showSizeChanger: true,
                    pageSizeOptions: [
                        5, 10, 15, 20, 100
                    ],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} de ${total} registros`,
                  }}
            >
                <Table.Column
                    dataIndex={["acf","ca_codformacion"]}
                    title="Cód. Formación"
                />
                <Table.Column
                    dataIndex={["title","rendered"]}
                    title="Nombre"
                />
                <Table.Column
                    dataIndex={["ca_ejeTematicoName"]}
                    title="Eje Temático"
                />
                <Table.Column
                    dataIndex={["acf", "ca_duracion"]}
                    title="Duración (h)"
                    render={(value: string) => <TagField value={value + ' h'} />}
                />
                <Table.Column 
                    dataIndex="status" 
                    title="Status" 
                    render={(value: string) => <TagField
                        value={value} 
                        color= {value = 'publish' ? 'green' : 'volcano'}
                    />}
                />

                <Table.Column
                    title="Actiones"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            {/* <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            /> */}
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            {/* <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                confirmTitle="¿Esta seguro de borarlo?"
                                confirmOkText="Si, estoy de acuerdo"
                                confirmCancelText="Cancelar"
                            /> */}
                        </Space>
                    )}
                />
            </Table>
        </List>
        </Authenticated>
    );
};
