import {
    AuthBindings,
} from "@refinedev/core";

import { axiosInstance } from "../../rest-data-provider/utils";

let API_URL;

if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ) { 
    API_URL = process.env.REACT_APP_API_URL_DEV;
} else {
    API_URL = process.env.REACT_APP_API_URL_PRO;
}
const WORDPRESS_API_LOGIN = `${API_URL}/wp-json/jwt-auth/v1/token`;

// axiosInstance.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         const customError: HttpError = {
//             ...error,
//             message: error.response?.data?.message,
//             statusCode: error.response?.status,
//         };

//         return Promise.reject(customError);
//     },
// );
export const setItemWithExpiry = (key: any, value: any, expireInSeconds: number) => {
    const now = new Date();
    const expireTime = now.getTime() + expireInSeconds * 1000; // Calcula el tiempo de expiración en milisegundos
    const item = {
      value,
      expireTime,
    };
    localStorage.setItem(key, JSON.stringify(item)); // Almacena el valor en el local storage como un objeto JSON
};

export const getItemWithExpiry = (key:any) => {
    const itemString = localStorage.getItem(key);
    if (!itemString) {
      return null;
    }
    const item = JSON.parse(itemString);
    const now = new Date();
    if (now.getTime() > item.expireTime) {
      localStorage.removeItem(key); // Elimina el valor si ha expirado
      return null;
    }
    return item.value;
};

const authProvider: AuthBindings = {
    login: async ({ email, password, remember, }) => {
        
        const login = await axiosInstance.post(
            WORDPRESS_API_LOGIN, 
            {
                "username": email,
                "password": password
            },)
            .then((response) => {
                let auth = JSON.stringify(response.data.token).toString().replaceAll('"','');
                let userData = JSON.stringify(response.data.user_display_name).toString().replaceAll('"','');
                
                let iddleTime = 900;

                setItemWithExpiry('auth', auth, iddleTime);
                // localStorage.setItem('auth', auth);
                
                setItemWithExpiry('userName', userData, iddleTime);
                // localStorage.setItem('userName', userData);

                axiosInstance.defaults.headers.common = {
                    Authorization: `Bearer ${auth}`,
                };

                return true
            })
            .catch((error) => {
                return false
            }
        );

        if (login) {
            return {
                success: true,
                redirectTo: "/",
            };
        }
        return {
            success: false,
            error: {
                message: "Error de Acceso",
                name: "Email o Password invalido",
            },
        };
    },
    logout: async () => {
        localStorage.removeItem("auth");
        localStorage.removeItem("userName");
        return {
            success: true,
            redirectTo: "/login",
        };
    },
    onError: async (error) => {
        console.error(error);
        return { error };
    },
    check: async () =>
        getItemWithExpiry("auth")
            ? {
                  authenticated: true,
              }
            : {
                  authenticated: false,
                  error: {
                      message: "Check failed",
                      name: "Not authenticated",
                  },
                  logout: true,
                  redirectTo: "/login",
              },
    getPermissions: async () => ["admin"],
    getIdentity: async () => {
        let user = getItemWithExpiry('userName')
        return {
        id: 1,
        name: user,
        avatar: `https://ui-avatars.com/api/?background=random&name=${user}`,
    }},
};

export default authProvider;