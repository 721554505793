// import React from "react";
import { IResourceComponentsProps, Authenticated } from "@refinedev/core";

import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, Select, InputNumber, Space, Row, Col } from "antd";

import { MONTHS, YEARS, MODALITY, STATUS_OF_POST } from "../../constants";

import { usePopulateSelect } from "../../components/populateSelect";

export const CalendarioEdit: React.FC<IResourceComponentsProps> = () => {

  const optionsEjesTematicos = usePopulateSelect({ resource: "ejetematico", data: { dataId: "id", dataCode: "title.rendered", } });
  const optionsFormaciones   = usePopulateSelect({ resource: "formacion",   data: { dataId: "id", dataCode: "title.rendered", } });
  
  const { formProps, saveButtonProps, formLoading } = useForm();

  return (
    <Authenticated>
      <Edit saveButtonProps={saveButtonProps} title="Editar Formación en Calendario" isLoading={formLoading}>
        <Form {...formProps} layout="vertical">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" flex={1}>
              <Form.Item
                label="Mes"
                name={["acf", "ca_mes"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
                >
                <Select options={MONTHS}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" flex={1}>
              <Form.Item
                label="Año"
                name={["acf", "ca_ano"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
                >
                <Select options={YEARS}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" flex={8}>
              <Form.Item
                label="Capacitación"
                name={["acf", "ca_formacion_id"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={optionsFormaciones} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col flex={18}>
              <Form.Item
                label="Eje Temático"
                name={["acf", "ca_ejetematico_id"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={optionsEjesTematicos} />
              </Form.Item>          
            </Col>
            <Col flex={2}>
              <Form.Item label="Duración (en horas)" name={["acf", "ca_duracion"]}>
                <InputNumber addonAfter=" h" />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Fechas"
                name={["acf", "ca_fechas"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Horario"
                name={["acf", "ca_horario"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Modalidad"
                name={["acf", "ca_modalidad"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={MODALITY} />
              </Form.Item>
            </Col>
            <Col flex={1}>
              <Form.Item
                label="Estatus"
                name={["status"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select options={STATUS_OF_POST} />
              </Form.Item>
            </Col>
          </Row>
          

        </Form>
      </Edit>
    </Authenticated>
  );
};
