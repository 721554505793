import React, { useState } from "react";
import {
    Refine,
    Authenticated,
} from "@refinedev/core";
import {
    notificationProvider,
    ThemedLayoutV2,
    ThemedTitleV2,
    ErrorComponent,
    AuthPage,
    RefineThemes,
} from "@refinedev/antd";
import {
    DashboardOutlined,
    CalendarOutlined,
    TeamOutlined,
    BarsOutlined,
} from "@ant-design/icons";

import esES from 'antd/locale/es_ES';

import routerProvider, {
    NavigateToResource,
    CatchAllNavigate,
    UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";

/**
 * import dataProvider and authProvider
 */ 
import authProvider from "components/authProvider";
import { dataProvider } from "./rest-data-provider";

import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { 
    ConfigProvider, theme } from "antd";
import { Header } from "components/header";

import "@refinedev/antd/dist/reset.css";

import { DashboardPage } from "pages/dashboard";
import { CalendarioList, CalendarioCreate, CalendarioEdit, CalendarioShow } from "pages/calendario";
import { FormacionList, FormacionCreate, FormacionEdit, FormacionShow } from "pages/formacion";
import { EjetematicoList, EjetematicoCreate, EjetematicoEdit, EjetematicoShow } from "pages/ejetematico";

const App: React.FC = () => {

    const API_URL = `${process.env.REACT_APP_API_URL}`;

    let DATA_API_URL;

    if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ) { 
        DATA_API_URL = process.env.REACT_APP_API_URL_DEV;
    } else {
        DATA_API_URL = process.env.REACT_APP_API_URL_PRO;
    }
    const WORDPRESS_API_URL = `${DATA_API_URL}/wp-json/wp/v2`;

    const [currentTheme, setCurrentTheme] = useState<"light" | "dark">("light");

    return (
        <BrowserRouter>
            {/* <ConfigProvider theme={RefineThemes.Blue} locale={esES} > */}
            <ConfigProvider 
                locale={esES}
                theme={{
                    algorithm:
                        currentTheme === "light"
                            ? theme.defaultAlgorithm
                            : theme.darkAlgorithm,
                }}
            >
                <Refine
                    dataProvider={{
                        default: dataProvider(API_URL),
                        wordpress: dataProvider(WORDPRESS_API_URL),
                    }}
                    routerProvider={routerProvider}
                    authProvider={authProvider}
                    resources={[
                        {
                            name: "dashboard",
                            list: "/",
                            meta: {
                                label: "Escritorio",
                                dataProviderName: "wordpress",
                                icon: <DashboardOutlined />,
                            },
                        },
                        {
                            name: "calendario",
                            list: "/calendario",
                            show: "/calendario/show/:id",
                            create: "/calendario/create",
                            edit: "/calendario/edit/:id",
                            meta: {
                                label: "Calendario",
                                icon: <CalendarOutlined />,
                                dataProviderName: "wordpress",
                                canDelete: true,
                            },
                        },
                        {
                            name: "formacion",
                            list: "/formacion",
                            show: "/formacion/show/:id",
                            create: "/formacion/create",
                            edit: "/formacion/edit/:id",
                            meta: {
                                label: "Formaciones",
                                icon: <TeamOutlined />,
                                dataProviderName: "wordpress",
                                canDelete: true,
                            },
                        },
                        {
                            name: "ejetematico",
                            list: "/ejetematico",
                            show: "/ejetematico/show/:id",
                            create: "/ejetematico/create",
                            edit: "/ejetematico/edit/:id",
                            meta: {
                                label: "Ejes Temáticos",
                                icon: <BarsOutlined />,
                                dataProviderName: "wordpress",
                                canDelete: true,
                            },
                        },
                    ]}
                    notificationProvider={notificationProvider}
                    options={{
                        syncWithLocation: true,
                        warnWhenUnsavedChanges: true,
                        reactQuery: {
                            devtoolConfig: false,
                        },
                    }}
                >
                    <Routes>
                        <Route
                            element={
                                <Authenticated
                                    fallback={<CatchAllNavigate to="/login" />}
                                >
                                    <ThemedLayoutV2 /* Header={Header} */
                                        Title={({ collapsed }) => (
                                            <ThemedTitleV2
                                                // collapsed is a boolean value that indicates whether the <Sidebar> is collapsed or not
                                                collapsed={collapsed}
                                                icon={
                                                    collapsed ?
                                                        <img src="./assets/images/svg/Logo-Small.svg" width="40px" /> :
                                                        <img src="./assets/images/svg/Logo-CAES-2020.svg" width="120px" />
                                                    }
                                                text=""
                                            />
                                        )}
                                    >
                                        <Outlet />
                                    </ThemedLayoutV2>
                                </Authenticated>
                            }
                        >
                            <Route index element={<DashboardPage />} />
                            <Route
                                element={
                                    <Authenticated redirectOnFail>
                                        <NavigateToResource resource="calendario" />
                                    </Authenticated>
                                }
                            />
                            <Route
                                element={
                                    <Authenticated redirectOnFail>
                                        <NavigateToResource resource="formacion" />
                                    </Authenticated>
                                }
                            />
                            <Route
                                element={
                                    <Authenticated redirectOnFail>
                                        <NavigateToResource resource="ejetematico" />
                                    </Authenticated>
                                }
                            />   

                            <Route path="calendario">
                                <Route index element={<CalendarioList />} />
                                <Route path="show/:id" element={<CalendarioShow />} />
                                <Route path="edit/:id" element={<CalendarioEdit />} />
                                <Route path="create" element={<CalendarioCreate />} />
                            </Route>
                            <Route path="formacion">
                                <Route index element={<FormacionList />} />
                                <Route path="show/:id" element={<FormacionShow />} />
                                <Route path="edit/:id" element={<FormacionEdit />} />
                                <Route path="create" element={<FormacionCreate />} />
                            </Route>
                            <Route path="ejetematico">
                                <Route index element={<EjetematicoList />} />
                                <Route path="show/:id" element={<EjetematicoShow />} />
                                <Route path="edit/:id" element={<EjetematicoEdit />} />
                                <Route path="create" element={<EjetematicoCreate />} />
                            </Route>
                        </Route>

                            <Route
                                path="/login"
                                element={
                                    <AuthPage 
                                        type="login"
                                        forgotPasswordLink={false}
                                        registerLink={false}
                                        rememberMe={false}
                                        title={<img src="./assets/images/svg/Logo-CAES-2020.svg" width="250px" />}
                                        wrapperProps={{
                                            style: {
                                                background: "#f0f0f0",
                                            },
                                        }}
                                        contentProps={{
                                            title: "Introduzca sus Datos",
                                            headStyle: {
                                                textAlign: "center",
                                                color: "rgb(64, 150, 255)",
                                                marginBottom: "0px",
                                                fontSize: "24px",
                                                lineHeight: "32px",
                                                fontWeight: "700",
                                            },
                                            bodyStyle: {
                                                // background: "#673ab742",
                                            },
                                        }}
                                    />
                                }
                            />
                        <Route
                            element={
                                <Authenticated>
                                    <ThemedLayoutV2>
                                        <Outlet />
                                    </ThemedLayoutV2>
                                </Authenticated>
                            }
                        >
                            <Route path="*" element={<ErrorComponent />} />
                        </Route>
                    </Routes>
                    <UnsavedChangesNotifier />
                </Refine>
            </ConfigProvider>
        </BrowserRouter>
    );
};

export default App;
