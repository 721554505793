import React from "react";
import { IResourceComponentsProps, useShow, Authenticated } from "@refinedev/core";
import { Show, NumberField, TagField, TextField } from "@refinedev/antd";
import { Typography, Form, Row, Col, Input, InputNumber, Select } from "antd";
import TinyMCEEditor from "../../components/tinyMCE";
const { Title } = Typography;

const { TextArea } = Input;

export const FormacionShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Authenticated>
      <Show isLoading={isLoading} title="Ver Formación" canDelete={false} canEdit={false}> 
        <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
          <Col className="gutter-row" flex={22}>
            <Title level={5}>Nombre</Title>
            <TextField value={record?.title.rendered ?? ""} />
          </Col>
          <Col className="gutter-row" flex={1}>
            <Title level={5}>Cód Formación</Title>
            <TextField value={record?.acf.ca_codformacion ?? "No definido"} />
          </Col>
          <Col className="gutter-row" flex={1}>
            <Title level={5}>Duración (h)</Title>
            <NumberField value={record?.acf.ca_duracion ?? 0} /> h
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
          <Col className="gutter-row" flex={2}>
            <Title level={5}>Cód Eje Temático</Title>
            <TextField value={record?.acf.ca_ejetematico_code ?? "No definido"} />
          </Col>
          <Col className="gutter-row" flex={20}>
            <Title level={5}>Eje Temático</Title>
            <TextField value={record?.ca_ejetematiconame ?? "No definido"} />
          </Col>
          <Col className="gutter-row" flex={2}>
            <Title level={5}>Status</Title>
            <TagField value={record?.status} />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
          <Col className="gutter-row" flex={24}>
            <Title level={5}>Objetivos</Title>
            <div
              dangerouslySetInnerHTML={{ __html: record?.content.rendered ?? "" }}
            />
          </Col>
        </Row>

        {/* <Form layout="vertical">
          <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
          <Col className="gutter-row" flex={22}>
              <Form.Item
                label="Nombre de la Formación"
                name={["title", "rendered"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" flex={1}>
              <Form.Item
                label="Cód. Formación"
                name={["acf", "ca_codformacion"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input style={{ width: '150px' }}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" flex={1}>
              <Form.Item
                label="Duración"
                name={["acf", "ca_duracion"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextField value={record?.acf.ca_duracion } />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
            <Col className="gutter-row" flex={20}>
              <Form.Item
                label="Eje Temático"
              >
                <TextField value={record?.ca_ejetematiconame ?? "No definido"} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" flex={4}>
              <Form.Item
                label="Estatus"
              >
              <TagField value={record?.status} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 16 }}>
            <Col className="gutter-row" flex={24}>
              <Form.Item label="Objetivos">
              <div
                  dangerouslySetInnerHTML={{__html: record?.content.rendered ?? ""}}
              />
              </Form.Item>
            </Col>
          </Row>
        </Form> */}
      </Show>
    </Authenticated>
  );
};
