import { useSelect } from "@refinedev/core";

interface ICode {
  id: string;
  code: string;
}

interface IFindNameProps {
  resource: string;
  dataProviderName?: string;
  data: {
    dataId: string;
    dataCode: string;
  };
}

export const useFindNames = ({
  resource,
  dataProviderName = "wordpress",
  data,
}: IFindNameProps) => {
  const { queryResult } = useSelect({
    dataProviderName,
    resource,
    pagination: {
      pageSize: 100,
    },
  });
 
  const dataCodePrefix = data.dataCode.split(".")[0];
  const dataCodeField = data.dataCode.split(".")[1];

  const names = queryResult.data?.data.map((item) => {
    let name;
    // if (dataCodePrefix === "acf") {
    if (dataCodePrefix) {
      name = item[dataCodePrefix][dataCodeField];
    } else {
      name = item[dataCodeField];
    }

    return {
      id: item[data.dataId],
      code: name,
    };
  }) as ICode[];

  return (id: string) => {
    const result = names.find((item) => item.id == id);
    return result ? result.code : null;
  };
};
