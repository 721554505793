import React, { useState, useEffect } from "react";
import { IResourceComponentsProps, Authenticated } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Row, Col, Input, Select, Switch } from "antd";

// import { useFindCodes } from "../../components/findCode";
import { usePopulateSelect } from "../../components/populateSelect";

export const EjetematicoCreate: React.FC<IResourceComponentsProps> = () => {
  const [disabled, setDisabled] = useState(false);
  const [caHasParent, setCaHasParent] = useState(false);

  const toggle = () => {
    setDisabled(!disabled);
    setCaHasParent(!caHasParent);
  };

  const ejeData = {
    dataId: "id",
    dataCode: "title.rendered",
  };

  const optionsEjesTematicos = usePopulateSelect({ resource: "ejetematico", data: ejeData });

  // const dataId = "id";
  // const dataCode = "acf.ca_ejetematico_code";
  // const data = {
  //   dataId,
  //   dataCode,
  // };
  
  // const findEjeTematicoCode = useFindCodes({ resource: "ejetematico", data: data });

  const { formProps, saveButtonProps } = useForm();

  return (
    <Authenticated>
      <Create saveButtonProps={saveButtonProps} title="Crear Eje Temático">
        <Form {...formProps} layout="vertical">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" flex={20}>
              <Form.Item
                label="Nombre del Eje Tematico"
                name={["title", "rendered"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>      
            </Col>
            <Col className="gutter-row" flex={2}>
              <Form.Item
                label="Cód Eje Temático"
                name={["acf", "ca_ejetematico_code"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
                >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" flex={2}>
              <Form.Item
                label="Estatus"
                name={["status"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
                >
                <Select>
                  <Select.Option value="publish">Publicado</Select.Option>
                  <Select.Option value="draft">Borrador</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" flex={2}>
            <Form.Item 
                label="¿Pertenece a otro Eje Temático?" 
                name={["acf", "ca_has_parent"]}
              >
                <Switch 
                  checked={caHasParent} 
                  checkedChildren="Si" 
                  unCheckedChildren="No" 
                  onClick={toggle} 
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" flex={20}>
            <Form.Item
                label="Eje Temático Superior"
                name={["acf", "ca_ejeTematicoParent_Id"]}
                rules={[
                  {
                    required: caHasParent,
                  },
                ]}
              >
                <Select options={optionsEjesTematicos} disabled={!disabled} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" flex={2}>
              <Form.Item
                label="Estatus"
                name={["status"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
                >
                <Select>
                  <Select.Option value="publish">Publicado</Select.Option>
                  <Select.Option value="draft">Borrador</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row> */}
        </Form>
      </Create>
    </Authenticated>
  );
};
