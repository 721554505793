import React from "react";
import { IResourceComponentsProps, BaseRecord, HttpError, Authenticated } from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ShowButton,
    DeleteButton,
    TagField,
    useImport, 
    ImportButton,
    ExportButton,
    CreateButton
} from "@refinedev/antd";
import { 
    Table, 
    Space,
} from "antd";

import { IEjeTematico, ImportEjeTematico } from "../../interfaces";
import { useFindCodes } from "../../components/findCode";

export const EjetematicoList: React.FC<IResourceComponentsProps> = () => {
    const ejeData = {
      dataId: "id",
      dataCode: "acf.ca_ejetematico_code" ,
    };
    
    const findEjeTematicoCode = useFindCodes({ resource: "ejetematico", data: ejeData });

    const { tableProps } = useTable<IEjeTematico, HttpError>();

    const importProps = useImport<ImportEjeTematico>({
        paparseOptions: {
            skipEmptyLines: true,
        },
        mapData: (item) => {
            let ejeTematico_id = ( 
                item.cod_ejetematicopadre != '' ? 
                    // String(findEjeTematicoCode(item.cod_ejetematicopadre)) : 
                    findEjeTematicoCode(item.cod_ejetematicopadre) : 
                    null
            );

            return {
                title: item.nombre,
                content: item.contenido,
                acf: {
                    ca_ejetematico_code: item.cod_ejetematico ?? null,
                    ca_has_parent: ejeTematico_id ? true : false,
                    ca_ejeTematicoParent_Id: ejeTematico_id ?? null,
                    ca_ejeTematicoParent_code: item.cod_ejetematicopadre ?? null,
                    ca_order: item.order ?? 0
                },
                status: 'publish'
            };
        },
    });

    return (
        <Authenticated>
        <List
            headerProps={{
                extra: (
                    <Space>
                        <ImportButton {...importProps} >Importar</ImportButton>
                        {/* <ExportButton
                            onClick={triggerExport}
                            loading={exportLoading}
                        >Exportar</ExportButton> */}
                        {/* <CreateButton>Agregar</CreateButton> */}
                    </Space>
                ),
            }}
        >
            <Table {...tableProps} 
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    showSizeChanger: true,
                    pageSizeOptions: [
                        5, 10, 15, 20
                    ],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} de ${total} registros`,
                  }}
            >
                <Table.Column
                    dataIndex={["title","rendered"]}
                    title="Nombre"
                />

                <Table.Column
                    dataIndex={["acf","ca_ejetematico_code"]}
                    title="Cód Eje Temático"
                />

                <Table.Column 
                    dataIndex="status" 
                    title="Estatus" 
                    render={(value: string) => <TagField
                            value={value} 
                            color= {value = 'publish' ? 'green' : 'volcano'}
                        />}
                />
                                
                <Table.Column
                    title="Actiones"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            {/* <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            /> */}
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            {/* <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                confirmTitle="¿Esta seguro de borarlo?"
                                confirmOkText="Si, estoy de acuerdo"
                                confirmCancelText="Cancelar"
                            /> */}
                        </Space>
                    )}
                />
            </Table>
        </List>
        </Authenticated>
    );
};
