// import React from "react";
import { IResourceComponentsProps, useShow, Authenticated } from "@refinedev/core";
import {
    Show,
    TagField,
    TextField,
} from "@refinedev/antd";
import { Typography } from "antd";

const { Title } = Typography;

export const CalendarioShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Authenticated>
            <Show isLoading={isLoading} title="Ver Formación en Calendario" canDelete={false} canEdit={false}>
                <Title level={5}>Mes</Title>
                <TextField value={record?.acf.ca_mes} />
                <Title level={5}>Año</Title>
                <TextField value={record?.acf.ca_ano} />
                <Title level={5}>Fechas</Title>
                <TextField value={record?.acf.ca_fechas} />
                <Title level={5}>Formación</Title>
                <TextField value={record?.ca_formacionName} />
                <Title level={5}>Eje Temático</Title>
                <TextField value={record?.ca_ejetematiconame} />
                <Title level={5}>Duración (h)</Title>
                <TextField value={record?.acf.ca_duracion} />
                <Title level={5}>Horario</Title>
                <TextField value={record?.acf.ca_horario} />
                <Title level={5}>Modalidad</Title>
                <TextField value={record?.acf.ca_modalidad} />
                <Title level={5}>Estatus</Title>
                <TextField value={record?.status} />
            </Show>
        </Authenticated>
    );
};
