export const MONTHS = [
    { label: "01", value: "Enero" },
    { label: "02", value: "Febrero" },
    { label: "03", value: "Marzo" },
    { label: "04", value: "Abril" },
    { label: "05", value: "Mayo" },
    { label: "06", value: "Junio" },
    { label: "07", value: "Julio" },
    { label: "08", value: "Agosto" },
    { label: "09", value: "Septiembre" },
    { label: "10", value: "Octubre" },
    { label: "11", value: "Noviembre" },
    { label: "12", value: "Diciembre" },
  ];

export const YEARS = [
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
    { label: "2026", value: "2026" },
  ];

 export const MODALITY = [
    { label: "Presencial",  value: "Presencial" },
    { label: "Virtual",     value: "Virtual" },
  ];

export const STATUS_OF_POST = [
    { label: "Publicado",     value: "publish" },
    { label: "Borrador",      value: "draft" },
  ];