import React, { useState, useEffect } from "react";
import { IResourceComponentsProps, Authenticated } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Row, Col, Input, Select, Switch } from "antd";

// import { useFindCodes } from "../../components/findCode";
import { usePopulateSelect } from "../../components/populateSelect";

export const EjetematicoEdit: React.FC<IResourceComponentsProps> = () => {
  
  const ejeData = {
    dataId: "id",
    dataCode: "title.rendered",
  };
  
  const optionsEjesTematicos = usePopulateSelect({ resource: "ejetematico", data: ejeData });
  
  const { form, queryResult, formProps, formLoading, saveButtonProps } = useForm();

  // const handleOnFinish  = (values : any) => {
  //   onFinish({
  //     ca_ejeTematicoParent_Id: (values.ca_has_parent ? values.ca_ejeTematicoParent_Id : null)
  //   })
  //   console.log(values)
  // };
  
  const [caHasParent, setCaHasParent] = useState(false);
  const [ejeParentId, setEjeParentId] = useState(null);
  const [disabled, setDisabled] = useState(caHasParent);

  let caHasParentValue = queryResult?.data?.data.acf.ca_has_parent;
  
  const ejeParent_Id = Form.useWatch(["acf", "ca_ejeTematicoParent_Id"], form);
  const toggle = () => {
    setDisabled(!disabled);
    setCaHasParent(!caHasParent);
    
    if (!disabled) {
      // console.log('otra '+ejeParent_Id);
      setEjeParentId(null);
      // Some code could be here
    }
  };
  
  return (
    <Authenticated>
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading} title="Editar Eje Temático">
      <Form {...formProps} layout="vertical">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" flex={20}>
            <Form.Item
              label="Nombre del Eje Tematico"
              name={["title", "rendered"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" flex={2}>
            <Form.Item
              label="Cód Eje Temático"
              name={["acf", "ca_ejetematico_code"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" flex={2}>
            <Form.Item
              label="Estatus"
              name={["status"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select>
                <Select.Option value="publish">Publicado</Select.Option>
                <Select.Option value="draft">Borrador</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" flex={2}>
            <Form.Item 
              label="¿Pertenece a otro Eje Temático?" 
              name={["acf", "ca_has_parent"]}
              // initialValue={caHasParentValue}
              >
              <Switch 
                // checked={caHasParent} 
                defaultChecked={caHasParentValue} 
                checkedChildren="Si" 
                unCheckedChildren="No" 
                onClick={toggle} 
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" flex={20}>
            <Form.Item
              label="Eje Temático"
              name={["acf", "ca_ejeTematicoParent_Id"]}
              // rules={[
              //   {
              //     required: !caHasParent,
              //   },
              // ]}
            >
              <Select 
                value={ejeParentId}
                options={optionsEjesTematicos} 
                disabled={caHasParent} 
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" flex={2}>
            <Form.Item
              label="Estatus"
              name={["status"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select>
                <Select.Option value="publish">Publicado</Select.Option>
                <Select.Option value="draft">Borrador</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row> */}
      </Form>
    </Edit>
    </Authenticated>
  );
};
