import React, { useState } from "react";
import { IResourceComponentsProps, Authenticated } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Row, Col, Input, InputNumber, Select } from "antd";
import { IFormacion, IEjeTematico } from "../../interfaces";
// import TextArea from "antd/es/input/TextArea";

import TinyMCEEditor from "../../components/tinyMCE";

export const FormacionCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IFormacion>();

  const { queryResult } = useSelect<IEjeTematico>({
    dataProviderName: "wordpress",
    resource: "ejetematico",
    pagination: {
      pageSize: 50,
    },
  });

  const options = queryResult.data?.data.map((item) => ({
    value: item.id,
    label: item.title.rendered,
  }));

  const [contentValue, setContentValue] = useState<string>(
    formProps.initialValues?.content?.rendered || ""
  );

  const handleContentChange = (value: string) => {
    setContentValue(value);
  };

  return (
    <Authenticated>
    <Create saveButtonProps={saveButtonProps} title="Crear Formación">
      <Form {...formProps} layout="vertical">
        <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
        <Col className="gutter-row" flex={22}>
            <Form.Item
              label="Nombre de la Formación"
              name={["title", "rendered"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col className="gutter-row" flex={1}>
            <Form.Item
              label="Cód. Formación"
              name={["acf", "ca_codformacion"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: '150px' }}/>
            </Form.Item>
          </Col>
          <Col className="gutter-row" flex={1}>
            <Form.Item
              label="Duración"
              name={["acf", "ca_duracion"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber min={1} max={999} addonAfter="h" style={{ width: '100px' }}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
          <Col className="gutter-row" flex={20}>
            <Form.Item
              label="Eje Temático"
              name={["acf", "ca_ejetematico_id"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={options} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" flex={4}>
            <Form.Item
              label="Estatus"
              name={["status"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select>
                <Select.Option value="publish">Publicado</Select.Option>
                <Select.Option value="draft">Borrador</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 16 }}>
          <Col className="gutter-row" flex={24}>
            <Form.Item label="Objetivos" name={["content", "rendered"]}>
              <TinyMCEEditor initialValue="" onChange={handleContentChange} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
    </Authenticated>
  );
};
