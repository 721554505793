import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm, useSimpleList } from "@refinedev/antd";
import { Form, Input, Select } from "antd";
import { IFormacion } from "../../interfaces";
import { MONTHS, YEARS, MODALITY, STATUS_OF_POST } from "../../constants";

import { Authenticated } from "@refinedev/core";

export const CalendarioCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();


  const { queryResult } = useSimpleList<IFormacion>({
    dataProviderName: "wordpress",
    resource: "formacion",
    pagination: {
      pageSize: 100,
    },
  });

  const optionsFormacion = queryResult.data?.data.map((item) => ({
    value: item.id,
    label: item.title.rendered,
  }));


  return (
    <Authenticated>
      <Create saveButtonProps={saveButtonProps} title="Calendario Capacitación">
        <Form {...formProps} layout="vertical">
          <Form.Item
            label="Mes"
            name={["acf", "ca_mes"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={MONTHS} />
          </Form.Item>
          <Form.Item
            label="Año"
            name={["acf", "ca_ano"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={YEARS} />
          </Form.Item>
          <Form.Item
            label="Capacitación"
            name={["acf", "ca_formacion_id"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={optionsFormacion} />
          </Form.Item>
          <Form.Item
            label="Duración"
            name={["acf", "ca_fechas"]}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Eje Temático"
            name={["acf", "ca_fechas"]}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Fechas"
            name={["acf", "ca_fechas"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Horario"
            name={["acf", "ca_horario"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Modalidad"
            name={["acf", "ca_modalidad"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={MODALITY} />
          </Form.Item>
          <Form.Item
            label="Estatus"
            name={["status"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={STATUS_OF_POST} />
          </Form.Item>
        </Form>
      </Create>
    </Authenticated>
  );
};
