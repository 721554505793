import * as React from "react";
import { 
    IResourceComponentsProps, 
    BaseRecord, 
    useExport, 
    useDeleteMany ,
    Authenticated
} from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ShowButton,
    DeleteButton,
    TagField,
    useImport, 
    ImportButton,
    ExportButton,
    CreateButton
} from "@refinedev/antd";
import { Table, Space, Button, notification, Modal, UploadProps } from "antd";
import { useFindCodes } from "../../components/findCode";
import { useFindNames } from "../../components/findName";

import { ImportacionCalendario, ICalendario } from "../../interfaces";
import { MONTHS } from "../../constants";

export const CalendarioList: React.FC<IResourceComponentsProps> = () => {

    const ejeId = "id";
    const ejeCode = "acf.ca_ejetematico_code";
    const ejeData = {
      dataId: ejeId,
      dataCode: ejeCode ,
    };

    const formacionId = "id";
    const formacionCode = "acf.ca_codformacion";
    const formacionData = {
      dataId: formacionId,
      dataCode: formacionCode,
    };
    
    const formacionName = "title.rendered";
    const formacionNames = {
      dataId: formacionId,
      dataCode: formacionName,
    };
        
    const findEjeTematicoCode = useFindCodes({ resource: "ejetematico", data: ejeData });
    
    const findCapacitacionCode = useFindCodes({ resource: "formacion", data: formacionData });
    const findCapacitacionName = useFindNames({ resource: "formacion", data: formacionNames });

    const findMonthValue = (search: string, returnType: 'label' | 'value' = 'value'): string | undefined => {
        if ( Number(search) ) { // Is a Number cold be search = "1" or search = "01"
            const monthNumber = search.toString().padStart(2, '0');
            const requiredMonth = MONTHS.find((requiredMonth) => requiredMonth.label === monthNumber);
            
            return (returnType === 'value') ? requiredMonth?.value : requiredMonth?.label;

        } else if (search) { // Is Not a Number could be Search = "Enero"
            const requiredMonth = MONTHS.find((month) => month.value.toLowerCase() === search.toLowerCase());

            return (returnType === 'value') ? requiredMonth?.value : requiredMonth?.label;
        };
        return undefined;
      };

    const DateParser = {
        months: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'],
        month: 0,
        year: 0,
        str: '',
        dates: [] as string[],
      
        initialize: function (month: string, year: string, str: string) {
          this.month = parseInt(month);
          this.year = parseInt(year);
          this.str = str;
          this.dates = [];
        },
      
        parse: function () {
          const array = this.str.split(/[\s,]+| y /);
          array.forEach((value: string) => {
            if (!isNaN(parseInt(value))) {
              let day = parseInt(value).toString().padStart(2, '0');
              let date = new Date(this.year, this.month - 1, parseInt(day));
              this.dates.push(`${day}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`);
            } else {
              let newMonth = this.months.indexOf(value.substr(0, 3).toUpperCase());
              if (newMonth !== -1) {
                this.month = newMonth + 1;
              }
              if (this.month > 12) {
                this.month = 1;
                this.year += 1;
              }
            }
          });
        },
      
        getInitialDate: function () {
          return this.dates[0];
        },
      
        getDates: function () {
          return this.dates.join('<br/>');
        },
      
        getSerializedDates: function () {
          return JSON.stringify(this.dates);
        },
      
        getUnserializedDates: function (serializedDates: string) {
          return JSON.parse(serializedDates);
        },
      };

    // Como usarlo
    // DateParser.initialize(2, 2022, "some string");
    // DateParser.parse();
    // console.log(DateParser.getDates());


    const expectedHeaders: (keyof ImportacionCalendario)[] = [
        "mes",
        "ano",
        "fechas",
        "cod_formacion",
        "nombre_formacion",
        "duracion",
        "horario",
        "modalidad",
        "objetivos",
        "cod_ejetematico",
        "instructor",
        "certificacion",
    ];

    const importProps = useImport<ImportacionCalendario>({
        paparseOptions: {
            skipEmptyLines: 'greedy',
            // header: true,
        },
        onFinish: (result) => {
            // success requests response
            // result.succeeded.forEach((item) => {
            //     console.log(item);
            // });
    
            // failed requests response
            result.errored.forEach((item) => {
                console.log(item);
            });
        },
        mapData: (item) => {
            let ejeTematico_id = String(findEjeTematicoCode(item.cod_ejetematico.trim())); // Ok
            let formacion_id = String(findCapacitacionCode(item.cod_formacion.trim()));
            let formacionName = findCapacitacionName(formacion_id);
            let mes = findMonthValue(item.mes.trim(), 'label'); // Ok

            let month = mes ? mes : "1";
            let ano = item.ano ? item.ano.trim() : "2023";
            let fechas = item.fechas ? item.fechas.trim() : "";

            DateParser.initialize(month, ano, fechas);
            DateParser.parse();
            let fechaDeInicio = DateParser.getInitialDate();
            // console.log(fechaDeInicio);

            // console.log('Eje_ID ', ejeTematico_id, ' Formacion ID ', formacion_id, 'Formacion Name ', formacionName, 'Mes ', mes);
            // console.log('Objetivos ', item.objetivos ?? 'Vacio');

            return {
                title: formacionName,
                // slug: formacionName,
                content: item.objetivos,
                acf: {
                    ca_mes: mes,
                    ca_ano: ano,
                    ca_fechas: fechas,
                    ca_fechaInicial: fechaDeInicio,
                    ca_formacion_id: formacion_id,
                    ca_formacion: formacionName,
                    ca_ejetematico_id: ejeTematico_id,
                    ca_duracion: Number(item.duracion),
                    ca_horario: item.horario.trim(),
                    ca_modalidad: item.modalidad.trim(),
                    ca_instructor: item.instructor.trim(),
                    ca_certificado_por: item.certificacion.trim(),
                },
                status: 'publish'
            };
        },
    });

    const { triggerExport, isLoading: exportLoading } = useExport<ICalendario>({
        mapData: (item) => {
            return {
                mes: findMonthValue(item.acf.ca_mes),
                ano: item.acf.ca_ano,
                fechas: item.acf.ca_fechas,
                formacionname: item.ca_formacionName,
                duracion: item.acf.ca_duracion,
                horario: item.acf.ca_horario,
                modalidad: item.acf.ca_modalidad,
                ejetematiconame: item.ca_ejeTematicoName,
                instructor: item.acf.ca_instructor,
                certificado: item.acf.ca_certificado_por,
            }
        }
    });

    const { tableProps } = useTable({
        syncWithLocation: true,
    });

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>(
        [],
    );

    const { mutate, isLoading: deleteManyIsLoading } = useDeleteMany<ICalendario>();

    const deleteSelectedItems = () => {
        mutate(
            {
                resource: "calendario",
                ids: selectedRowKeys.map(String),
            },
            {
                onSuccess: () => {
                    setSelectedRowKeys([]);
                },
            },
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ],
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
        <Authenticated>     
            <List
                title = "Calendario"
                headerProps={{
                    subTitle: (
                        <>
                            <Button
                                type="primary"
                                onClick={deleteSelectedItems}
                                disabled={!hasSelected}
                                loading={deleteManyIsLoading}
                            >
                                Borrar
                            </Button>
                            <span style={{ marginLeft: 8 }}>
                                {hasSelected
                                    ? `Se han seleccionado ${selectedRowKeys.length} items`
                                    : ""}
                            </span>
                        </>
                    ),
                    extra: (
                        <Space>
                            <ImportButton {...importProps} >Importar</ImportButton>
                            <ExportButton
                                onClick={triggerExport}
                                loading={exportLoading}
                            >Exportar</ExportButton>
                            {/* <CreateButton>Agregar</CreateButton> */}
                        </Space>
                    ),
                }}
            >
                <Table {...tableProps} 
                    rowSelection={rowSelection}
                    rowKey="id"
                    pagination={{
                        ...tableProps.pagination,
                        showSizeChanger: true,
                        pageSizeOptions: [
                            5, 10, 15, 20, 100
                        ],
                        showTotal: (total, range) =>
                        `${range[0]}-${range[1]} de ${total} registros`,
                    }}
                >
                    <Table.Column
                        dataIndex={["acf","ca_mes"]}
                        title="Mes"
                        render={(value: string) => {
                            const monthValue = findMonthValue(value);
                            return <span>{monthValue}</span>;
                        }}
                    />
                    <Table.Column
                        dataIndex={["acf","ca_ano"]}
                        title="Año"
                    />
                    <Table.Column
                        dataIndex={["acf","ca_fechas"]}
                        title="Fechas"
                    />
                    <Table.Column
                        dataIndex={["ca_formacionName"]}
                        title="Capacitación"
                    />
                    <Table.Column 
                        dataIndex={["acf","ca_duracion"]} 
                        title="Duración" 
                        render={(value: string) => <TagField value={value + ' h'} />}
                    />
                    <Table.Column 
                        dataIndex={["acf","ca_horario"]} 
                        title="Horario" 
                    />
                    <Table.Column 
                        dataIndex={["acf","ca_modalidad"]} 
                        title="Modalidad" 
                    />
                    <Table.Column 
                        dataIndex="status" 
                        title="Estatus" 
                        render={(value: string) => <TagField
                            value={value} 
                            color= {value = 'publish' ? 'green' : 'volcano'}
                        />}
                    />

                    <Table.Column
                        title="Actiones"
                        dataIndex="actions"
                        render={(_, record: BaseRecord) => (
                            <Space>
                                {/* <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                /> */}
                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                {/* <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    confirmTitle="¿Esta seguro de borarlo?"
                                    confirmOkText="Si, estoy de acuerdo"
                                    confirmCancelText="Cancelar"
                                /> */}
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </Authenticated>
    );
};
