import { useGetIdentity, usePermissions, Authenticated } from "@refinedev/core";

import { Row, Col, Card, Avatar, Typography, Space, Statistic  } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
    const { data: identity } = useGetIdentity<{
        id: string;
        name: string;
        avatar: string;
    }>();
    const permissions = usePermissions<string[]>();

    return (
        <Authenticated>
        <Row gutter={20}>
            {/* <Col span={8}>
                <Card
                    title="Capacitaciones Agendadas"
                    style={{ height: "300px", borderRadius: "15px" }}
                    headStyle={{ textAlign: "center" }}
                >
                    <Statistic
                        title="Active"
                        value={11.28}
                        precision={2}
                        valueStyle={{ color: '#3f8600' }}
                        prefix={<ArrowUpOutlined />}
                        suffix="%"
                    />
                </Card>
            </Col>
            <Col span={8}>
                <Card
                    title="Cantidad de Capacitaciones"
                    style={{ height: "300px", borderRadius: "15px" }}
                    headStyle={{ textAlign: "center" }}
                >
                    <Text>{permissions.data}</Text>
                </Card>
            </Col>
            <Col span={8}>
                <Card
                    title="Ejes Temáticos"
                    style={{ height: "300px", borderRadius: "15px" }}
                    headStyle={{ textAlign: "center" }}
                >
                    <Text>{permissions.data}</Text>
                </Card>
            </Col> */}
        </Row>
        </Authenticated>
    );
};
