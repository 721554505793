import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";

const TINYMCE_KEY = `${process.env.REACT_APP_TINYMCE_KEY}`;

interface Props {
  initialValue: string;
  onChange: (value: string) => void;
}

const TinyMCEEditor: React.FC<Props> = ({ initialValue, onChange }) => {
  const [editorContent, setEditorContent] = useState(initialValue);

  useEffect(() => {
    setEditorContent(initialValue);
  }, [initialValue]);

  const handleEditorChange = (value: string) => {
    setEditorContent(value);
    onChange(value);
  };

  const editorConfig = {
    language: "es",
    menubar: false,
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
      "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
  };

  return (
    <Editor
      apiKey={TINYMCE_KEY}
      value={editorContent}
      onEditorChange={handleEditorChange}
      init={editorConfig}
    />
  );
};

export default TinyMCEEditor;
