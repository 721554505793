import React, { useState, useEffect } from "react";
import {
  IResourceComponentsProps,
  useShow,
  HttpError,
  Authenticated
} from "@refinedev/core";
import { Show, TextField } from "@refinedev/antd";
import { Form, Row, Col, Switch } from "antd";
import ShowRelatedRecord from "../../components/ShowRelatedRecord"

export const EjetematicoShow: React.FC<IResourceComponentsProps> = () => {
  const [caHasParent, setCaHasParent] = useState(false);
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  interface IRecord {
    title: {
      rendered: string;
    };
  }

  const record = data?.data;

  useEffect(() => {
    setCaHasParent(record?.acf.ca_has_parent);
  }, [record]);

  return (
    <Authenticated>
    <Show isLoading={isLoading} title="Ver Eje Temático" canDelete={false} canEdit={false}>
      <Form layout="vertical">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" flex={20}>
            <Form.Item label="Nombre del Eje Tematico">
              <TextField value={record?.title["rendered"]} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" flex={2}>
            <Form.Item label="Cód Eje Temático">
              <TextField value={record?.acf["ca_ejetematico_code"]} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" flex={2}>
            <Form.Item label="Estatus">
              <TextField value={record?.status} />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" flex={2}>
            <Form.Item label="¿Pertenece a otro Eje Temático?">
              <Switch
                checked={caHasParent}
                checkedChildren="Si"
                unCheckedChildren="No"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" flex={20}>
            <Form.Item
              label="Eje Temático Superior"
            >
              <ShowRelatedRecord<IRecord, HttpError>
                resource="ejetematico"
                id={record?.acf.ca_ejeTematicoParent_Id}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" flex={2}>
            <Form.Item label="Estatus">
              <TextField value={record?.status} />
            </Form.Item>
          </Col>
        </Row> */}
      </Form>
    </Show>
    </Authenticated>
  );
};
