import { useSelect } from "@refinedev/core";

interface ICode {
  id: number;
  code: string;
}

interface IFindCodesProps {
  resource: string;
  dataProviderName?: string;
  data: {
    dataId: string;
    dataCode: string;
  };
}

export const useFindCodes = ({
  resource,
  dataProviderName = "wordpress",
  data,
}: IFindCodesProps) => {

  const { queryResult } = useSelect({
    dataProviderName,
    resource,
    pagination: {
      pageSize: 100,
    },
  });
 
  const dataCodePrefix = data.dataCode.split(".")[0];
  const dataCodeField = data.dataCode.split(".")[1];

  const codes = queryResult.data?.data.map((item) => {
    let code;

    if (dataCodePrefix) {
      code = item[dataCodePrefix][dataCodeField];
    } else {
      code = item[dataCodeField];
    }

    return {
      id: item[data.dataId],
      code: code,
    };
  }) as ICode[];

  return (code: string) => {
    const result = codes.find((item) => item.code === code);
    return result ? result.id : null;
  };
};
