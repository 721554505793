import { useSelect } from "@refinedev/core";

interface ISelect {
  value: string;
  label: string;
}

interface IFindNameProps {
  resource: string;
  dataProviderName?: string;
  data: {
    dataId: string;
    dataCode: string;
  };
}

export const usePopulateSelect = ({
  resource,
  dataProviderName = "wordpress",
  data,
}: IFindNameProps) => {
  const { queryResult } = useSelect({
    dataProviderName,
    resource,
    debounce: 500,
    queryOptions: {
        retry: 3,
    },
    pagination: {
      pageSize: 100,
      mode: "server"
    },
  });
 
  const dataCodePrefix = data.dataCode.split(".")[0];
  const dataCodeField = data.dataCode.split(".")[1];

  const names = queryResult.data?.data.map((item) => {
    let name;
    // if (dataCodePrefix === "acf") {
    if (dataCodePrefix) {
      name = item[dataCodePrefix][dataCodeField];
    } else {
      name = item[dataCodeField];
    }

    return {
      value: item[data.dataId],
      label: name,
    };
  }) as ISelect[];

  return names;
};
