// "axios" package needs to be installed
import { AxiosInstance } from "axios";
// "stringify" function is re-exported from "query-string" package by "@refinedev/simple-rest"
import { stringify } from "@refinedev/simple-rest";
import { DataProvider, HttpError, /* BaseRecord, MetaQuery, BaseKey */ } from "@refinedev/core";
import {
  axiosInstance,
  generateSort,
  generateFilter,
  generateWPSort,
} from "./utils";
import { getItemWithExpiry } from "components/authProvider";

const token = getItemWithExpiry("auth");

const config = {
  headers: { Authorization: `Bearer ${token}` },
};

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     const customError: HttpError = {
//       ...error,
//       message: error.response?.data?.message,
//       statusCode: error.response?.status,
//     };

//     return Promise.reject(customError);
//   }
// );

// Definir la interfaz del DataProvider personalizado
// interface CustomDataProvider extends DataProvider {
//   createMany: (params: { resource: string; data: any[] }) => Promise<{ data: any }>;
//   updateMany: (params: { resource: string; ids: string[]; data: any }) => Promise<{ data: any }>;
//   deleteMany: (params: { resource: string; ids: string[] }) => Promise<{ data: any }>;
// }

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
  // Is a testing implementation for try to implement createMany, updateMany and deleteMany
): DataProvider  => ({
  // Original implementation commented for testing pruporses
  // ): Omit<
  //   Required<DataProvider>,
  //   "createMany" | "updateMany" | "deleteMany"
  // > => ({
  getList: async ({ resource, pagination, filters, sorters }) => {
    const url = `${apiUrl}/${resource}`;

    const { current = 1, pageSize = 10 } = pagination ?? {};

    const queryFilters = generateFilter(filters);
    const generatedWPSort = generateWPSort(sorters);

    const query: {
      page?: number;
      per_page?: number;
      orderby?: string;
      order?: string;
    } = {
      // Representa _start
      page: current,
      // Representa _end
      per_page: pageSize,
    };

    if (generatedWPSort) {
      const { orderby, order } = generatedWPSort;
      query.orderby = orderby.join(",");
      query.order = order.join(",");
    }

    const { data, headers } = await httpClient.get(
      `${url}?${stringify(query)}&${stringify(queryFilters)}`, config
    );

    const total = +headers["x-wp-total"];
    const totalPages = +headers["x-wp-totalpages"];

    return {
      data,
      total: total || totalPages,
    };
  },

  getMany: async ({ resource, ids }) => {
    const { data } = await httpClient.get(
      `${apiUrl}/${resource}?${stringify({ id: ids })}`, config
    );

    return {
      data,
    };
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    const { title, content, ...otherVariables } = variables as {
      [key: string]: any;
    };

    const newVariables = {
      ...otherVariables,
      title: typeof title === "object" ? title.rendered : title,
      content: typeof content === "object" ? content.rendered : content,
    };

    const { data } = await httpClient.post(url, newVariables, config);

    return {
      data,
    };
  },

  // createMany: async ({ resource, variables }) => {
  //   const url = `${apiUrl}/${resource}`;

  //   const { data } = await httpClient.post(url, variables, config); 
  //   return { data };
  // },

  // createMany: async ({ resource, variables }) => {
  //   const url = `${apiUrl}/${resource}/bulk`;

  //   const { title, content, ...otherVariables } = variables as {
  //     [key: string]: any;
  //   };

  //   const newVariables = {
  //     ...otherVariables,
  //     title: typeof title === "object" ? title.rendered : title,
  //     content: typeof content === "object" ? content.rendered : content,
  //   };

  //   const { data } = await httpClient.post(url, { values: variables });

  //   return {
  //     data,
  //   };
  // },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    // console.log(variables)

    const { title, content, ...otherVariables } = variables as {
      [key: string]: any;
    };

    const newVariables = {
      ...otherVariables,
      title: typeof title === "object" ? title.rendered : title,
      content: typeof content === "object" ? content.rendered : content,
    };

    // console.log(newVariables)

    const { data } = await httpClient.put(url, newVariables, config);

    return {
      data,
    };
  },

  updateMany: async ({ resource, ids, variables }) => {
    const url = `${apiUrl}/${resource}/bulk`;
    const { data: responseData } = await httpClient.put(url, { ids, values: variables }, config);
    return { data: responseData };
  },

//   updateMany: async ({ resource, ids, variables }) => {
//     const url = `${apiUrl}/${resource}/bulk`;
//     const { data } = await axiosInstance.patch(url, { ids, variables });

//     return {
//         data,
//     };
// },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.get(url, config);

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.delete(url, config);

    return {
      data,
    };
  },

  // deleteMany: async ({ resource, ids }) => {
  //   const url = `${apiUrl}/${resource}/bulk`;
  //   const { data: responseData } = await httpClient.delete(url, { data: { ids } });
  //   return { data: responseData };
  // },
  // deleteMany: async ({ resource, ids }) => {
  //   const url = `${apiUrl}/${resource}/bulk?ids=${ids.join(",")}`;
  //   const { data } = await axiosInstance.delete(url);

  //   return {
  //     data,
  //   };
  // },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({
    url,
    method,
    filters,
    sorters,
    payload,
    query,
    headers,
  }) => {
    let requestUrl = `${url}?`;

    if (sorters) {
      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(","),
          _order: _order.join(","),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers = {
        ...httpClient.defaults.headers,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload);
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url, {
          data: payload,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
